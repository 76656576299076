// index.js
import JSZip from "jszip";
import { generateSvgString, svgToPngDataUrl, resetToDefault } from "./generateSvgString.js";
import {
  renderIntoCanvas,
  initializeEventListeners,
  configProxy,
} from "./render.js";
import { LOGO_COLOR_VARIANTS } from "./config.js";
import  { setUpInitialSvgDimension } from "./render.js";


setUpInitialSvgDimension();
// Initial render
renderIntoCanvas();
initializeEventListeners();

// Generate button click event
const generateButton = document.getElementById("_4hc_btn_generate");
if (generateButton) {
  generateButton.addEventListener("click", async () => {
    // Regular Variant
    const regularSvgString = await generateSvgString(LOGO_COLOR_VARIANTS.ORIGINAL);
    const regularSvgElement = new DOMParser().parseFromString(regularSvgString, 'image/svg+xml').documentElement;
    const regularPngDataUrl = await svgToPngDataUrl(regularSvgElement);

    // Black Variant
    const blackSvgString = await generateSvgString(LOGO_COLOR_VARIANTS.BLACK);
    const blackSvgElement = new DOMParser().parseFromString(blackSvgString, 'image/svg+xml').documentElement;
    const blackPngDataUrl = await svgToPngDataUrl(blackSvgElement);

    // Reverse Variant
    const reverseSvgString = await generateSvgString(LOGO_COLOR_VARIANTS.REVERSE);
    const reverseSvgElement = new DOMParser().parseFromString(reverseSvgString, 'image/svg+xml').documentElement;
    const reversePngDataUrl = await svgToPngDataUrl(reverseSvgElement);

    const zip = new JSZip();

    // Add PNG files
    zip.folder("png").file("4hc_canada_logo.png", regularPngDataUrl.split(",")[1], { base64: true });
    zip.folder("png").file("4hc_canada_logo_black.png", blackPngDataUrl.split(",")[1], { base64: true });
    zip.folder("png").file("4hc_canada_logo_reverse.png", reversePngDataUrl.split(",")[1], { base64: true });

    // Add SVG files
    zip.folder("svg").file("4hc_canada_logo.svg", regularSvgString);
    zip.folder("svg").file("4hc_canada_logo_black.svg", blackSvgString);
    zip.folder("svg").file("4hc_canada_logo_reverse.svg", reverseSvgString);

    zip.generateAsync({ type: "blob" })
      .then(function (content) {
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(content);
        downloadLink.download = "4hc_canada_logo.zip";
        downloadLink.click();
      })
      .catch(function (error) {
        console.error("Error creating zip file:", error);
      });

    resetToDefault();
  });
}
