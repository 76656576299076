import { textToPath, FONTS_NAME } from "./fontUtils";
import { config, LOGO_COLOR_VARIANTS } from "./config";

const svgElement = document.getElementById("_4hc_logo");

export const SIZE = 1000;
export const p = (x) => (x * SIZE) / 100;

export const resetToDefault = () => {
  svgElement.setAttribute("width", 350);
  svgElement.setAttribute("height", 350);

  changeLogoColorAccordingToVariants(LOGO_COLOR_VARIANTS.ORIGINAL);

  svgElement.querySelectorAll('.st1').forEach((el) => {
    el.setAttribute("fill", "#fff");
  });
}

export const generateSvgString = async (colorVariant) => {
  // Update the club name path element
  await updateClubTextName();
  await updateProvinceName();

  // Update the styles dynamically if needed
  changeLogoColorAccordingToVariants(colorVariant);

  const serializer = new XMLSerializer();
  svgElement.setAttribute("width", SIZE);
  svgElement.setAttribute("height", SIZE);
  let svgString = serializer.serializeToString(svgElement);

  return svgString;
};

export const svgToPngDataUrl = async (svgElement) => {
  const svgString = new XMLSerializer().serializeToString(svgElement);
  const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
  const url = URL.createObjectURL(svgBlob);

  const img = new Image();
  img.src = url;

  return new Promise((resolve, reject) => {
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = 1000; // Set canvas width to 1000
      canvas.height = 1000; // Set canvas height to 1000
      const context = canvas.getContext('2d');

      // Clear canvas
      context.clearRect(0, 0, canvas.width, canvas.height);

      // Draw image to fill canvas
      context.drawImage(img, 0, 0, canvas.width, canvas.height);
      URL.revokeObjectURL(url);
      resolve(canvas.toDataURL('image/png'));
    };
    img.onerror = (err) => {
      URL.revokeObjectURL(url);
      reject(err);
    };
  });
};


export const changeLogoColorAccordingToVariants = (color) => {
  svgElement.querySelectorAll(".st0, ._4hc_line, #_4hc_club_name,#_4hc_line2,#_4hc_line3, #_4hc_club_province")
    .forEach((el) => {
      el.setAttribute("fill", color);
    });

  svgElement.querySelectorAll('.st1').forEach((el) => {
    if (color == '#fff') el.setAttribute("fill", "#0E8442");
    else el.setAttribute("fill", "#fff");
  });
};

export const updateClubTextName = async () => {
  let position = p(10)
  // Generate path data for the club name
  if (!config.line2 && !config.line3) position = p(20)
  if (config.line2 && !config.line3) position = p(15)
  const clubNameResult = await textToPath(config.line1, (SIZE / 2), position, 42, FONTS_NAME.GOTHAM_BOOK);

  const clubNamePathElement = svgElement.querySelector("#_4hc_club_name");
  clubNamePathElement.setAttribute("d", clubNameResult);
};

export const updateProvinceName = async () => {
  const clubProvinceResult = await textToPath(
    config.province,
    p(50),
    p(72),
    48,
    FONTS_NAME.GOTHAM_BOOK
  );

  const clubProvincePathElement = svgElement.querySelector(
    "#_4hc_club_province"
  );
  clubProvincePathElement.setAttribute("d", clubProvinceResult);
};

export const updateLineSecond = async () => {
  // if (!config.line2) return;
  let position = p(15)
  if (config.line2 && !config.line3) position = p(20)
  let renderText = config.line2
  if (renderText == null) renderText = ""
  const clubNameResult = await textToPath(
    renderText,
    p(50),
    position,
    42,
    FONTS_NAME.GOTHAM_BOOK
  );

  const clubNamePathElement = svgElement.querySelector("#_4hc_line2");
  clubNamePathElement.setAttribute("d", clubNameResult);
}

export const updateLineThird = async () => {
  let renderText = config.line3
  if (renderText == null) renderText = ""
  const clubNameResult = await textToPath(
    renderText,
    p(50),
    p(20),
    42,
    FONTS_NAME.GOTHAM_BOOK
  );

  const clubNamePathElement = svgElement.querySelector("#_4hc_line3");
  clubNamePathElement.setAttribute("d", clubNameResult);
}