import { updateClubTextName, updateLineSecond, updateLineThird, updateProvinceName } from "./generateSvgString.js";

// config.js
export const config = {
  line1: "Your club name",
  line2: null,
  line3: null,
  province: "4-H Ontario",
  lineVisibility: 1,
};

export const configHandler = {
  set(obj, prop, value) {
    console.log(`Setting ${prop} to ${value}`);
    if (prop in obj) {
      obj[prop] = value;
      // renderIntoCanvas();

      updateClubTextName();
      updateProvinceName();

      updateLineSecond();
      updateLineThird();

      
    }
  },
};

export const LOGO_COLOR_VARIANTS = {
  REVERSE: "#fff",
  BLACK: "#000",
  ORIGINAL: "#0E8442",
};



// Proxy to handle configuration updates
export const configProxy = new Proxy(config, configHandler);