// render.js
import { config, configProxy } from "./config.js";
import { updateClubTextName, updateProvinceName, updateLineSecond, updateLineThird, SIZE, p } from "./generateSvgString.js";

export const renderIntoCanvas = () => {
  updateClubTextName();
  updateProvinceName();

  updateLineSecond();
  updateLineThird();
};

export const updateLineValue = (lineName) => {
  console.log(lineName)
}

export const initializeEventListeners = () => {
  // lines input field change
  Array.from(document.querySelectorAll("._4hc_input")).forEach((lineInput) =>
    lineInput.addEventListener("input", (e) => {
      console.log(e.target.value);
      const lineName = e.target.id.split("_").pop();

      let value = e.target.value;

      if (lineName === "province") value = "4-H " + value;
      configProxy[lineName] = value;
    })
  );

  const inputProvince = document.getElementById("_4hc_input_province");
  if (inputProvince) {
    inputProvince.addEventListener("change", (e) => {
      const province = "4-H " + e.target.value;
      configProxy.province = "4-H " + e.target.value;
      console.log(province);
    });
  }

  const btnPlus = document.getElementById("_4hc_btn_plus");
  if (btnPlus) {
    btnPlus.addEventListener("click", () => {
      if (config.line3 !== null) return;
      else if (config.line3 === null && config.line2 !== null) {
        const inputElement = document.createElement("input");
        inputElement.type = "text";
        inputElement.id = "_4hc_input_line3";
        inputElement.value = "Line 3";
        inputElement.classList.add("_4hc_input");
        inputElement.classList.add("_4hc_align_self_left");
        inputElement.addEventListener('keyup', function (event) {
          const value = event.target.value
          configProxy.line3 = value
        });
        document.getElementById("_4hc_line").appendChild(inputElement);
        configProxy.line3 = "Line 3";
      }
      else if (config.line2 === null && config.line1 !== null) {
        const inputElement = document.createElement("input");
        inputElement.type = "text";
        inputElement.id = "_4hc_input_line2";
        inputElement.value = "Line 2";
        inputElement.classList.add("_4hc_input");
        inputElement.classList.add("_4hc_align_self_left");
        inputElement.addEventListener('keyup', function (event) {
          const value = event.target.value
          configProxy.line2 = value
        });
        document.getElementById("_4hc_line").appendChild(inputElement);
        configProxy.line2 = "Line 2";
      }
    });
  }
  const btnMinus = document.getElementById("_4hc_btn_minus");

  if (btnMinus) {
    btnMinus.addEventListener("click", () => {
      if (config.line3 !== null) {
        document.getElementById("_4hc_input_line3").remove()
        configProxy.line3 = null;
      }
      else if (config.line2 !== null) {
        document.getElementById("_4hc_input_line2").remove()
        configProxy.line2 = null;
        // console.log()
      }
    });
  }
};

// Set initial svg dimension

export const setUpInitialSvgDimension = () => {
  const svgElement = document.getElementById("_4hc_logo");
  svgElement.setAttribute("width", 350);
  svgElement.setAttribute("height", 350);
  svgElement.setAttribute("viewBox", `0 0 ${SIZE} ${SIZE}`);

  const clubLeaf = svgElement.querySelector("#_4hc_logo_leaf");
  const _clubLeafWidth = p(100)
  const _clubLeafHeight = p(50)
  clubLeaf.setAttribute("width", _clubLeafWidth);
  clubLeaf.setAttribute("height", _clubLeafHeight);
  clubLeaf.setAttribute("viewBox", `0 0 1000 1000`);

  clubLeaf.setAttribute("x", p(0));
  clubLeaf.setAttribute("y", p(20));
}