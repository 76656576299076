import gothamBook from "../assets/fonts/gotham-book/Gotham-Book.woff";
import gothamBold from "../assets/fonts/gotham-bold/Gotham-Bold.woff";
import opentype from "opentype.js";

export const FONTS_NAME = {
  GOTHAM_BOLD: "GOTHAM_BOLD",
  GOTHAM_BOOK: "GOTHAM_BOOK",
};

export const getFont = (fontName) => {
  switch (fontName) {
    case FONTS_NAME.GOTHAM_BOLD:
      return gothamBold;
    case FONTS_NAME.GOTHAM_BOOK:
      return gothamBook;
    default:
      return gothamBook;
  }
};

export const textToPath = (text, x = 0, y = 150, fontSize = 72, fontName) => {
  const fontPath = getFont(fontName);

  return new Promise((resolve, reject) => {
    opentype.load(fontPath, function (err, font) {
      if (err) reject("Font could not be loaded: " + err);
      else {
        if (!font) reject("Font could not be loaded");
        else {
          const width = font.getAdvanceWidth(text, fontSize);
          const path = font.getPath(text, x - (width / 2), y, fontSize); // Adjust x to center the text
          const d = path.toPathData();
          resolve(d);
        }
      }
    });
  });
};
